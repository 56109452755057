<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <i-select v-model="query.type" clearable size="small" placeholder="租户类型">
          <i-option v-for="item in tenantTypes" :key="'type_' + item.id" :value="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col span="6">
        <i-input v-model="query.keyword" size="small" placeholder="关键字：租户名称"></i-input>
      </i-col>
      <i-col span="2">
        <Button type="primary" icon="ios-search" size="small" @click="handleSearch">搜索</Button>
      </i-col>
      <i-col span="13" class="text-right">
        <i-button type="success" size="small" @click="handleAddTenant">租户录入</i-button>
      </i-col>
    </Row>
    <Table stripe size="small" :data="tableData.list" :columns="tableColumns" :loading="tableLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-elevator show-sizer
        :current="query.pageNumber" @on-change="handleChangePage" :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'></Page>
    </div>
    <tenant-modify ref="tenantModify" :typeArray="tenantTypes" :getListData="initData"></tenant-modify>
    <tenant-detail ref="tenantDetail"></tenant-detail>
  </div>
</template>
<script>
import { getAvailabLeadType } from '@/api/os/publishercompany'
import { getAdvertiserPage } from '@/api/crm/advertiser'

import TenantModify from './TenantModify.vue'
import TenantDetail from './TenantDetail.vue'
export default {
  components: {
    TenantModify,
    TenantDetail
  },
  data () {
    return {
      userInfo: this.$store.getters.token.userInfo,
      pagesizeSetting: [15, 50, 100, 200, 500],
      tenantTypes: [],
      query: {
        pageSize: 15,
        pageNumber: 1,
        sign: 2,
        type: null,
        publisherId: null,
        keyword: ''
      },
      tableLoading: false,
      tableData: {
        list: [],
        totalRow: 0
      },
      tableColumns: [
        { title: '租户名称', key: 'name' },
        { title: '租户类型', align: 'center', key: 'typeName' },
        { title: '状态', align: 'center', key: 'companyAdvertiserStatusName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleViewDetail(params.row)
                }
              }
            }, '详情')
          }
        }
      ]
    }
  },
  created () {
    this.query.publisherId = this.userInfo.publisherId
    this.getTenantTypeData()
    this.initData()
  },
  methods: {
    initData () {
      this.getTenantData()
      this.getTenantStaData()
    },
    getTenantData () {
      this.tableLoading = true
      getAdvertiserPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableData = Object.assign(this.tableData, res)
        }
      }).finally(() => { this.tableLoading = false })
    },
    getTenantTypeData () {
      getAvailabLeadType({ publisherId: this.userInfo.publisherId, companyId: this.userInfo.companyId, type: 2 }).then(res => {
        this.tenantTypes = []
        if (res && !res.errcode) {
          this.tenantTypes = Object.keys(res).map(x => {
            return { id: parseInt(x), name: res[x] }
          })
        }
      })
    },
    /**
     * 获取租户统计数据
     */
    getTenantStaData () {
      const query = {
        type: this.query.type,
        keyword: this.query.keyword,
        statistics: 1
      }
      this.$store.dispatch('getTenantStatusStaData', query).finally(() => {
        query.statistics = 2
        this.$store.dispatch('getTenantStatusStaData', query)
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
      // this.getTenantData()
      // this.getTenantStaData()
    },
    handleChangePage (curPageNumber) {
      this.query.pageNumber = curPageNumber
      this.getTenantData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.getTenantData()
    },
    handleAddTenant () {
      this.$refs.tenantModify.showModal()
    },
    handleViewDetail (params) {
      this.$refs.tenantDetail.showModal(params)
    }
  }
}
</script>
