import request from '@/utils/requestV2'
const qs = require('qs')

// 获取公司在媒介主下可用的客户类型
export function getAvailabLeadType (data) {
  return request({
    url: '/ooh-os/v1/publishercompany/getavailableadtype',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 获取支持adx的租户列表
export function getadxpublisher (data) {
  return request({
    url: '/ooh-os/v1/publishercompany/getadxpublisher',
    method: 'POST',
    data: qs.stringify(data)
  })
}
